// deno-lint-ignore-file

import { showMessage } from "./message-helper";
import fetch from "node-fetch";
import { type RequestInit, type Response } from "node-fetch";
import { Company, FullScope, PreDefinedFullScope } from "src/admin/data";

export async function callGetUserData(middletierToken: string): Promise<any> {
  try {
    const response = await fetch(`/feeData/getuserdata`, {
      method: "GET",
      headers: { Authorization: "Bearer " + middletierToken },
    });
    // console.log("get userdata response", response);
    return await response.json();
  } catch (err) {
    showMessage(`Error from middle tier. \n${err.responseText || err.message}`);
    throw err;
  }
}

async function callGetPrivateData(
  middletierToken: string,
  url: string,
  options?: RequestInit,
): Promise<Response> {
  if (!options) {
    options = {};
  }
  if (!options?.headers?.["Authorization"]) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers["Authorization"] = "Bearer " + middletierToken;
  }
  const response = await fetch(url, options);
  return response;
}
export async function callGetStaffList(middletierToken: string): Promise<any> {
  return callGetPrivateData(middletierToken, "/feeData/stafflist").then((r) =>
    r.json()
  );
}

export async function callSaveCustomCompany(
  middletierToken: string,
  company: Company,
): Promise<any> {
  return callGetPrivateData(
    middletierToken,
    `/feeData/predefinedcompanies/${company.shortName}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(company),
    },
  ).then((r) => r.text());
}

export async function callGetStandardScopeList(
  middletierToken: string,
): Promise<any> {
  return callGetPrivateData(middletierToken, "/feeData/standardscopes").then((
    r,
  ) => r.json());
}

export async function callGetStandardFullScopeList(
  middletierToken: string,
): Promise<FullScope[]> {
  return callGetPrivateData(middletierToken, "/feeData/standardfullscopes")
    .then((r) => r.json());
}

export async function callGetPredefinedScopesList(
  middletierToken: string,
): Promise<any> {
  return callGetPrivateData(middletierToken, "/feeData/predefinedscopes").then((
    r,
  ) => r.json());
}

export async function callGetPredefinedFullScopesList(
  middletierToken: string,
): Promise<PreDefinedFullScope[]> {
  return callGetPrivateData(middletierToken, "/feeData/predefinedfullscopes")
    .then((r) => r.json());
}

export async function callGetPredefinedCompaniesList(
  middletierToken: string,
): Promise<any> {
  return callGetPrivateData(middletierToken, "/feeData/predefinedcompanies")
    .then((r) => r.json());
}
export async function callGetCfdLimitations(
  middletierToken: string,
): Promise<any> {
  return callGetPrivateData(middletierToken, "/feeData/cfdlimitations").then((
    r,
  ) => r.json());
}

export async function callGetStaffPhoto(
  middletierToken: string,
  id: string,
): Promise<string> {
  return callGetPrivateData(middletierToken, `/feeData/pic/${id}`).then((r) =>
    r.text()
  );
}

export async function callGetSmallStaffPhoto(
  middletierToken: string,
  id: string,
): Promise<string> {
  return callGetPrivateData(middletierToken, `/feeData/smallPic/${id}`).then((
    r,
  ) => r.text());
}
