// deno-lint-ignore-file
/* global document, Office, Word, console */

import { Company, Deliverable, FullScope, PreDefinedFullScope, StaffMember } from "../admin/data.ts";
import {
  getCfdLimitations,
  getPredefinedCompaniesList,
  getPredefinedFullScopeList,
  getSmallStaffPhoto,
  getStaffList,
  getStandardFullScopeList,
  getUserData,
  saveCustomCompany,
} from "../helpers/sso-helper.ts";
import { getStaffPhoto } from "../helpers/sso-helper.ts";
import { FilterList } from "./listFilter.ts";

export async function runUserInfo() {
  const result = await getUserData();
  writeDataToOfficeDocument(result);
}

export function writeDataToOfficeDocument(result: Object): Promise<any> {
  return Word.run(function (context) {
    let data: string[] = [];
    let userProfileInfo: string[] = [];
    userProfileInfo.push(result["displayName"]);
    userProfileInfo.push(result["jobTitle"]);
    userProfileInfo.push(result["mail"]);
    userProfileInfo.push(result["mobilePhone"]);
    userProfileInfo.push(result["officeLocation"]);

    for (let i = 0; i < userProfileInfo.length; i++) {
      if (userProfileInfo[i] !== null) {
        data.push(userProfileInfo[i]);
      }
    }

    const documentBody: Word.Body = context.document.body;
    for (let i = 0; i < data.length; i++) {
      if (data[i] !== null) {
        documentBody.insertParagraph(data[i], "End");
      }
    }
    return context.sync();
  });
}

let globalCompanies = [];
let globalStaff: Map<string, StaffMember> = new Map();
let globalStandardScopes: FullScope[] = [];
let globalPreDefinedScopes: PreDefinedFullScope[] = [];
let globalCfdLimitations = [];
let filterList;

enum Role {
  Manager = "Project Manager",
  Supervisor = "Project Supervisor",
  Engineer = "Project Engineer",
  Surveyor = "Project Surveyor",
}

async function pageSetup() {
  async function populatePredefinedCompanies() {
    const companies = await getPredefinedCompaniesList();
    return new Promise((resolve) => {
      globalCompanies = companies;
      const select = document.querySelector("#predefined_companies_list");
      select.innerHTML = "";
      console.log("companies:", companies);
      for (const company of companies) {
        const option = document.createElement("option");
        option.value = company.shortName;
        option.innerText = company.shortName;
        select.appendChild(option);
      }
      resolve(null);
    });
  }
  async function populatePredefinedScopes() {
    const preDefinedScopes = await getPredefinedFullScopeList();
    globalPreDefinedScopes = preDefinedScopes;
    const select = document.querySelector("#predefined_scope_options");
    select.innerHTML = "";
    for (const company of preDefinedScopes) {
      const option = document.createElement("option");
      option.value = company.shortName;
      option.innerText = company.shortName;
      select.appendChild(option);
    }
  }
  async function populateStandardScopes() {
    const standardScopes: FullScope[] = await getStandardFullScopeList();
    globalStandardScopes = standardScopes;
    const list = document.querySelector("#standard_scope_details_list");
    list.innerHTML = "";
    let i = 0;
    for (const scope of standardScopes) {
      const li = document.createElement("li");
      const input = document.createElement("input");
      input.type = "checkbox";
      input.id = `scopeItem${i}-check`;
      input.name = `scopeItem${i}-check`;
      const label = document.createElement("span");
      label.innerText = scope.name;
      label.id = `scopeItem${i}-label`;
      li.appendChild(input);
      li.appendChild(label);
      list.appendChild(li);
      i++;
    }
  }
  async function populateStaff() {
    const staff = await getStaffList();
    for (const sm of staff) {
      globalStaff.set(sm.id, sm);
    }
    const qualsList = document.querySelector("#qualifications>tbody");
    const list = document.querySelector("#staff_list") as HTMLUListElement;
    list.innerHTML = "";
    let i = 0;
    const smallPhotoTasks = [];
    for (const [, staffMember] of globalStaff) {
      {
        const li = document.createElement("li");
        const img = document.createElement("img");
        img.classList.add("staff_list_photo");
        const useSmallPhotos = true;
        if (useSmallPhotos) {
          smallPhotoTasks.push(() =>
            getSmallStaffPhoto(staffMember.id)
              .then((photo: string | undefined) => {
                if (photo && photo !== "nophoto") {
                  img.src = `data:image/jpeg;base64,${photo}`;
                } else {
                  img.src = "/assets/user-square.svg";
                }
              })
              .catch((e) => console.warn(e))
          );
        }
        const label = document.createElement("span");
        label.innerText = staffMember.name;
        label.id = `staffItem${i}-label`;
        li.setAttribute("data-name", staffMember.name);
        li.appendChild(img);
        li.appendChild(label);
        list.appendChild(li);
        i++;
        li.addEventListener("click", () => {
          list.querySelectorAll("li").forEach((item) => {
            item.classList.remove("staff-selected");
          });
          li.classList.add("staff-selected");
          currentStaffMember = staffMember.id;
        });
      }
      {
        const li = document.createElement("tr");
        const label = document.createElement("td");
        label.innerText = staffMember.name;
        const qual = document.createElement("td");
        qual.innerText = staffMember.qualifications ? staffMember.qualifications : "";
        li.appendChild(label);
        li.appendChild(qual);
        qualsList.appendChild(li);
      }
    }
    const filterInput = document.getElementById("staffFilterInput") as HTMLInputElement;
    filterList = new FilterList(list, filterInput);
    console.log("smallPhotoTasks", smallPhotoTasks);
    try {
      (async () => {
        for (const r of smallPhotoTasks) {
          await r();
        }
      })();
      // const result = await Promise.allSettled(smallPhotoTasks);
      // console.log("small photo result:", result);
    } catch (e) {
      console.error(e);
    }
  }
  async function populateCfdLimitations() {
    globalCfdLimitations = await getCfdLimitations();
  }
  function updateCompanyType(companyType: string) {
    if (companyType === "predefined_company") {
      predefinedCompany = true;
      (document.querySelector("#custom_company_details") as HTMLElement).style.display = "none";
      (document.querySelector("#predefined_company_details") as HTMLElement).style.display = "flex";
      document.getElementById("custom_company").classList.remove("selected-tab");
      document.getElementById("predefined_company").classList.add("selected-tab");
    } else if (companyType === "custom_company") {
      predefinedCompany = false;
      (document.querySelector("#custom_company_details") as HTMLElement).style.display = "flex";
      (document.querySelector("#predefined_company_details") as HTMLElement).style.display = "none";
      document.getElementById("predefined_company").classList.remove("selected-tab");
      document.getElementById("custom_company").classList.add("selected-tab");
    } else {
      throw new Error("Invalid company type");
    }
  }
  function updateScopeType(scopeType: string) {
    if (scopeType === "predefined_scopes") {
      predefinedScopes = true;
      (document.querySelector("#standard_scope_details") as HTMLElement).style.display = "none";
      (document.querySelector("#predefined_scope_details") as HTMLElement).style.display = "inherit";
      document.getElementById("predefined_scopes").classList.add("selected-tab");
      document.getElementById("standard_scopes").classList.remove("selected-tab");
    } else if (scopeType === "standard_scopes") {
      predefinedScopes = false;
      (document.querySelector("#standard_scope_details") as HTMLElement).style.display = "inherit";
      (document.querySelector("#predefined_scope_details") as HTMLElement).style.display = "none";
      document.getElementById("standard_scopes").classList.add("selected-tab");
      document.getElementById("predefined_scopes").classList.remove("selected-tab");
    } else {
      throw new Error("Invalid scope type");
    }
  }
  function updateMainTab(tab: string) {
    if (tab === "general") {
      document.querySelectorAll(".scopeContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.querySelectorAll(".generalContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "unset";
      });
      document.querySelectorAll(".qualificationsContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.getElementById("generalTab").classList.add("selected-tab");
      document.getElementById("scopeTab").classList.remove("selected-tab");
      document.getElementById("qualificationsTab").classList.remove("selected-tab");
    } else if (tab === "scope") {
      document.querySelectorAll(".scopeContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "unset";
      });
      document.querySelectorAll(".generalContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.querySelectorAll(".qualificationsContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.getElementById("scopeTab").classList.add("selected-tab");
      document.getElementById("generalTab").classList.remove("selected-tab");
      document.getElementById("qualificationsTab").classList.remove("selected-tab");
    } else if (tab === "qualifications") {
      document.querySelectorAll(".qualificationsContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "unset";
      });
      document.querySelectorAll(".generalContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.querySelectorAll(".scopeContent").forEach((elem) => {
        (elem as HTMLElement).style.display = "none";
      });
      document.getElementById("qualificationsTab").classList.add("selected-tab");
      document.getElementById("generalTab").classList.remove("selected-tab");
      document.getElementById("scopeTab").classList.remove("selected-tab");
    } else {
      throw new Error("Invalid tab");
    }
  }
  document.getElementById("custom_company").addEventListener("click", () => updateCompanyType("custom_company"));
  document
    .getElementById("predefined_company")
    .addEventListener("click", () => updateCompanyType("predefined_company"));

  document.getElementById("predefined_scopes").addEventListener("click", () => updateScopeType("predefined_scopes"));
  document.getElementById("standard_scopes").addEventListener("click", () => updateScopeType("standard_scopes"));

  document.getElementById("generalTab").addEventListener("click", () => updateMainTab("general"));
  document.getElementById("scopeTab").addEventListener("click", () => updateMainTab("scope"));
  document.getElementById("qualificationsTab").addEventListener("click", () => updateMainTab("qualifications"));

  updateCompanyType("predefined_company");
  updateScopeType("predefined_scopes");
  updateMainTab("general");
  // TODO: we want to be able to do this simulatenously.
  // Promise.all([
  //   populatePredefinedCompanies(),
  //   populatePredefinedScopes(),
  //   populateStandardScopes(),
  //   populateStaff(),
  //   populateCfdLimitations(),
  // ]);
  await populatePredefinedCompanies();
  await populatePredefinedScopes();
  await populateStandardScopes();
  await populateStaff();
  await populateCfdLimitations();
}
let currentStaffMember: string | undefined;
let currentSelectedStaffMember: string | undefined;
let predefinedCompany: boolean = false;
let predefinedScopes: boolean = false;
let currentStaff: Map<string, string> = new Map();

function updateCurrentStaff() {
  const list = document.getElementById("selected_staff_list") as HTMLUListElement;
  list.innerHTML = "";
  for (const [staffId, role] of currentStaff.entries()) {
    const employee = globalStaff.get(staffId);
    const li = document.createElement("li");
    li.innerHTML = `<span class="staff_role">${role}</span>: <span class="staff_name">${employee.name}</span>`;
    list.appendChild(li);
    li.addEventListener("click", () => {
      list.querySelectorAll("li").forEach((item) => {
        item.classList.remove("staff-selected");
      });
      li.classList.add("staff-selected");
      currentSelectedStaffMember = staffId;
    });
  }
}

Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    document.getElementById("sideload-msg").style.display = "none";
    document.getElementById("app-body").style.display = "flex";
    document.getElementById("runSpec").onclick = runSpec;
    document.getElementById("runScope").onclick = runScope;
    document.getElementById("save_company").onclick = saveCompany;

    document.getElementById("add_supervisor_button").addEventListener("click", () => {
      if (currentStaffMember) {
        currentStaff.set(currentStaffMember, Role.Supervisor);
        updateCurrentStaff();
      }
    });
    document.getElementById("add_manager_button").addEventListener("click", () => {
      if (currentStaffMember) {
        currentStaff.set(currentStaffMember, Role.Manager);
        updateCurrentStaff();
      }
    });
    document.getElementById("add_engineer_button").addEventListener("click", () => {
      if (currentStaffMember) {
        currentStaff.set(currentStaffMember, Role.Engineer);
        updateCurrentStaff();
      }
    });
    document.getElementById("add_surveyor_button").addEventListener("click", () => {
      if (currentStaffMember) {
        currentStaff.set(currentStaffMember, Role.Surveyor);
        updateCurrentStaff();
      }
    });
    document.getElementById("remove_staff").addEventListener("click", () => {
      if (currentSelectedStaffMember) {
        currentStaff.delete(currentSelectedStaffMember);
        currentSelectedStaffMember = undefined;
        updateCurrentStaff();
      }
    });
  }
  Word.run(async (context) => {
    await pageSetup();

    await context.sync();
    (document.getElementById("projectType") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:WorkType"
    );
    (document.getElementById("clientPersonName") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:Client/fee:PersonName"
    );
    (document.querySelector("#predefined_company") as HTMLInputElement).checked = false;
    (document.querySelector("#custom_company") as HTMLInputElement).checked = true;

    (document.getElementById("custom_company_name") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:Client/fee:CompanyName"
    );
    (document.getElementById("custom_company_address") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:Client/fee:CompanyAddress"
    );
    (document.getElementById("piLevel") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:PILevel"
    );
    (document.getElementById("projectNumber") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:ProjectNumber"
    );
    (document.getElementById("variantNumber") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:Variant"
    );
    (document.getElementById("revisionNumber") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:Revision"
    );
    (document.getElementById("projectName") as HTMLInputElement).value = await getFeePropertyFirst(
      "/fee:FeeDetails/fee:ProjectName"
    );
    const cfdV = await getFeePropertyFirst("/fee:FeeDetails/fee:CFD");
    (document.getElementById("cfd") as HTMLInputElement).checked = cfdV === "true" ? true : false;
    context.sync();
  });
});

async function addNamespace(fs: Office.CustomXmlPart, prefix: string, namespace: string): Promise<void> {
  return new Promise((resolve, reject) => {
    fs.namespaceManager.addNamespaceAsync(prefix, namespace, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve();
      } else {
        reject(result.error);
      }
    });
  });
}

async function getXmlParts(): Promise<Office.CustomXmlPart[]> {
  return new Promise((resolve, reject) => {
    Office.context.document.customXmlParts.getByNamespaceAsync(feeNamespace, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

async function getNodes(feeStructure: Office.CustomXmlPart, spec: string): Promise<Office.CustomXmlNode[]> {
  return new Promise((resolve, reject) => {
    feeStructure.getNodesAsync(spec, function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const nodes = [];
        for (const node of result.value) {
          nodes.push(node);
        }
        resolve(nodes);
      } else {
        reject(result.error);
      }
    });
  });
}

const feeNamespace = "https://docs.affinity-fire.com/schemas/feev1";

async function getText(node): Promise<string> {
  return new Promise((resolve, reject) => {
    node.getTextAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

async function getFeeNodeFirst(spec: string): Promise<Office.CustomXmlNode | undefined> {
  const feeStructures = await getXmlParts();
  for (const feeStructure of feeStructures) {
    await addNamespace(feeStructure, "fee", feeNamespace);
    const theseNodes = await getNodes(feeStructure, spec);
    for (const node of theseNodes) {
      return node;
    }
  }
}

async function getFeePropertyFirst(spec: string): Promise<string | undefined> {
  const feeStructures = await getXmlParts();
  for (const feeStructure of feeStructures) {
    await addNamespace(feeStructure, "fee", feeNamespace);
    const theseNodes = await getNodes(feeStructure, spec);
    for (const node of theseNodes) {
      return await getText(node);
    }
  }
}

async function setFeePropertyFirst(spec: string, value: string): Promise<void> {
  const node = await getFeeNodeFirst(spec);
  if (node) {
    return new Promise((resolve, reject) => {
      node.setTextAsync(value, (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve();
        } else {
          reject(result.error);
        }
      });
    });
  } else {
    throw new Error(`No such node: ${spec}`);
  }
}

function getCompanyInfo(shortName: string): {
  shortName: string;
  name: string;
  address: string[];
} {
  for (const company of globalCompanies) {
    if (company.shortName === shortName) {
      return company;
    }
  }
}

// TODO: if predefined, retrieve from table
function getCompanyAddress() {
  if (predefinedCompany) {
    const shortName = (document.querySelector("#predefined_companies_input") as HTMLSelectElement).value;
    const company = getCompanyInfo(shortName);
    if (company) {
      let addr = "";
      let firstLine = true;
      for (const addrCmp of company.address) {
        if (!firstLine) {
          addr += "\n";
        } else {
          firstLine = false;
        }
        addr += addrCmp;
      }
      return addr;
    } else {
      return shortName;
    }
  } else {
    return (document.querySelector("#custom_company_address") as HTMLSelectElement).value;
  }
}

function getCompanyName() {
  if (predefinedCompany) {
    const shortName = (document.querySelector("#predefined_companies_input") as HTMLSelectElement).value;
    const company = getCompanyInfo(shortName);
    if (company) {
      return company.name;
    } else {
      return shortName;
    }
  } else {
    return (document.querySelector("#custom_company_name") as HTMLSelectElement).value;
  }
}

function roleIndex(role: string): number {
  // TODO: change to switch statement
  if (role === Role.Supervisor) {
    return 0;
  } else if (role === Role.Manager) {
    return 2;
  } else if (role === Role.Engineer) {
    return 3;
  } else if (role === Role.Surveyor) {
    return 3;
  } else {
    return 9999999;
  }
}

export async function saveCompany(e: MouseEvent) {
  e.preventDefault();
  const detailsSpinner = document.getElementById("detailsSpinner");
  detailsSpinner.classList.remove("hide");
  return Word.run(async (context) => {
    const shortName = (document.getElementById("custom_company_label") as HTMLInputElement).value;
    const name = (document.getElementById("custom_company_name") as HTMLInputElement).value;
    const address = (document.getElementById("custom_company_address") as HTMLInputElement).value?.split("\n");
    const company: Company = {
      shortName,
      name,
      address,
    };
    console.log("Saving Company:", company);
    await saveCustomCompany(company);
    await context.sync();
    detailsSpinner.classList.add("hide");
  });
}

export async function runSpec(e: MouseEvent) {
  e.preventDefault();
  const detailsSpinner = document.getElementById("detailsSpinner");
  detailsSpinner.classList.remove("hide");
  let cfd = false;
  return Word.run(async (context) => {
    {
      const projectName = (document.getElementById("projectName") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:ProjectName", projectName);

      const projectType = (document.getElementById("projectType") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:WorkType", projectType);

      const clientPersonName = (document.getElementById("clientPersonName") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:Client/fee:PersonName", clientPersonName);

      cfd = (document.getElementById("cfd") as HTMLInputElement).checked;
      await setFeePropertyFirst("/fee:FeeDetails/fee:CFD", `${cfd}`);

      const clientCompanyName = getCompanyName();
      await setFeePropertyFirst("/fee:FeeDetails/fee:Client/fee:CompanyName", clientCompanyName);

      const clientCompanyAddress = getCompanyAddress();
      await setFeePropertyFirst("/fee:FeeDetails/fee:Client/fee:CompanyAddress", clientCompanyAddress);

      const piLevel = (document.getElementById("piLevel") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:PILevel", piLevel);

      const projectNumber = (document.getElementById("projectNumber") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:ProjectNumber", projectNumber);

      const variantNumber = (document.getElementById("variantNumber") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:Variant", variantNumber);

      const revisionNumber = (document.getElementById("revisionNumber") as HTMLInputElement).value;
      await setFeePropertyFirst("/fee:FeeDetails/fee:Revision", revisionNumber);

      await setFeePropertyFirst(
        "/fee:FeeDetails/fee:DocumentReference",
        `AFF_${projectNumber}_${variantNumber}_FEP_${revisionNumber}`
      );
    }
    await context.sync();

    // Limitations
    {
      const ccs = context.document.contentControls.getByTag("limitations");
      ccs.load("items");
      await context.sync();
      for (const cc of ccs.items) {
        const content = new ScopeContent(context, cc);
        if (cfd) {
          content.headings = false;
          console.log("globalCfdLimitations", globalCfdLimitations);
          if (globalCfdLimitations) {
            for (const limitation of globalCfdLimitations) {
              await content.insertDeliverable(limitation);
            }
          }
        }
        content.insertEmptyLine();
      }
    }

    // Team
    {
      const ccs = context.document.contentControls.getByTag("team");
      ccs.load("items");
      await context.sync();
      for (const cc of ccs.items) {
        let projectStaff = [];
        for (const [staffId, role] of currentStaff.entries()) {
          const member = globalStaff.get(staffId);
          if (member) {
            projectStaff.push([role, member]);
          }
        }
        projectStaff.sort(([roleA, nameA], [roleB, nameB]) => {
          if (roleIndex(roleA) < roleIndex(roleB)) {
            return -1;
          }
          if (roleIndex(roleA) > roleIndex(roleB)) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        cc.clear();
        if (projectStaff.length > 0) {
          {
            const p = cc.insertParagraph("The nominated project team for this project are as follows:", "End");
            p.style = "AFF Body Text";
          }
          cc.font.highlightColor = null;
          const table = cc.insertTable(Math.ceil(projectStaff.length / 2), 2, "End");
          table.autoFitWindow();
          const border = table.getBorder(Word.BorderLocation.all);
          border.type = "None";
          try {
            await setStaff(table, projectStaff);
          } catch (e) {
            console.error(e);
          }
          {
            const p = cc.insertParagraph(
              "Note: This team may be amended upon appointment to ensure that the resourcing need of the project are met.",
              "End"
            );
            const ranges = p.split([":"], false, true);
            const note = ranges.getFirstOrNullObject();
            await context.sync();
            if (!note.isNullObject) {
              note.font.bold = true;
            }
            p.style = "AFF Body Text";
          }
        } else {
          const p = cc.insertParagraph("", "End");
          p.style = "AFF Body Text";
        }
      }
    }

    await context.sync();
    detailsSpinner.classList.add("hide");
  });
}

class ScopeContent {
  public currentList?: Word.List;
  public headings = true;
  constructor(private context: Word.RequestContext, public cc: Word.ContentControl) {
    cc.clear();
    cc.style = "AFF Body Text";
  }

  insertEmptyLine() {
    const p = this.cc.insertParagraph("", "End");
    p.style = "AFF Body Text";
  }

  async insertDeliverable(deliverable: Deliverable) {
    const p = this.cc.insertParagraph(deliverable.title, "End");
    p.style = "AFF Body Text";
    if (deliverable.items) {
      await this.insertList(deliverable.items);
    }
    if (this.headings) {
      p.style = "AFF Table Header";
    }
  }

  async insertList(deliverables: Deliverable[], level: number = 1, listIn?: Word.List) {
    let list = listIn;
    console.log("inserting list:", deliverables);
    for (const deliverable of deliverables) {
      let p;
      if (!list) {
        p = this.cc.insertParagraph(deliverable.title, "End");
        // TODO: this new line is not ideal, but otherwise the list starts to
        // leave the content control box.
        this.cc.insertParagraph("", "End");
        list = p.startNewList();
        p.listItem.level = level - 1;
      } else {
        p = list.insertParagraph(deliverable.title, "End");
        p.listItem.level = level - 1;
      }
      if (deliverable.items && deliverable.items.length > 0) {
        this.insertList(deliverable.items, level + 1, list);
      }
    }
  }
}

class DeliverablesContent {
  public currentList?: Word.List;
  public headings = true;
  constructor(private context: Word.RequestContext, public cc: Word.ContentControl) {
    cc.clear();
    cc.style = "AFF Body Text";
  }

  insertEmptyLine() {
    const p = this.cc.insertParagraph("", "End");
    p.style = "AFF Body Text";
  }

  async insertDeliverable(deliverable: Deliverable) {
    const p = this.cc.insertParagraph(deliverable.title, "End");
    p.style = "AFF Body Text";
    if (deliverable.items) {
      await this.insertList(deliverable.items);
    }
    if (this.headings) {
      p.style = "AFF Table Header";
    }
  }

  async insertList(deliverables: Deliverable[], level: number = 1, listIn?: Word.List) {
    let list = listIn;
    console.log("inserting list:", deliverables);
    for (const deliverable of deliverables) {
      let p;
      if (!list) {
        p = this.cc.insertParagraph(deliverable.title, "End");
        // TODO: this new line is not ideal, but otherwise the list starts to
        // leave the content control box.
        this.cc.insertParagraph("", "End");
        list = p.startNewList();
        p.listItem.level = level - 1;
      } else {
        p = list.insertParagraph(deliverable.title, "End");
        p.listItem.level = level - 1;
      }
      if (deliverable.items && deliverable.items.length > 0) {
        this.insertList(deliverable.items, level + 1, list);
      }
    }
  }
}

export async function runScope(e: MouseEvent) {
  e.preventDefault();
  const scopeSpinner = document.getElementById("scopeSpinner");
  scopeSpinner.classList.remove("hide");
  return Word.run(async (context) => {
    // Scope
    {
      const ccs = context.document.contentControls.getByTag("scope");
      ccs.load("items");
      await context.sync();
      for (const cc of ccs.items) {
        let scope: Deliverable[];
        if (predefinedScopes) {
          const predefinedName = (document.getElementById("predefined_scope_options") as HTMLInputElement).value;
          for (const predefinedScope of globalPreDefinedScopes) {
            if (predefinedScope.shortName === predefinedName) {
              scope = predefinedScope.scope.scope;
            }
          }
        } else {
          scope = [];
          const nlis = document.querySelectorAll("#standard_scope_details_list li").length;
          for (let i = 0; i < nlis; i++) {
            const isUsed = (document.getElementById(`scopeItem${i}-check`) as HTMLInputElement).checked;
            const title = (document.getElementById(`scopeItem${i}-label`) as HTMLElement).innerText;
            if (isUsed) {
              for (const fullScope of globalStandardScopes) {
                if (fullScope.name === title) {
                  for (const thisScope of fullScope.scope) {
                    scope.push(thisScope);
                  }
                  break;
                }
              }
            }
          }
        }
        if (scope) {
          const content = new ScopeContent(context, cc);
          for (const scopeItem of scope) {
            await content.insertDeliverable(scopeItem);
          }
        }
      }
    }
    // Deliverables
    {
      const ccs = context.document.contentControls.getByTag("deliverables");
      ccs.load("items");
      await context.sync();
      for (const cc of ccs.items) {
        let deliverables: Deliverable[];
        if (predefinedScopes) {
          const predefinedName = (document.getElementById("predefined_scope_options") as HTMLInputElement).value;
          for (const predefinedScope of globalPreDefinedScopes) {
            if (predefinedScope.shortName === predefinedName) {
              deliverables = predefinedScope.scope.deliverables;
            }
          }
        } else {
          deliverables = [];
          const nlis = document.querySelectorAll("#standard_scope_details_list li").length;
          for (let i = 0; i < nlis; i++) {
            const isUsed = (document.getElementById(`scopeItem${i}-check`) as HTMLInputElement).checked;
            const title = (document.getElementById(`scopeItem${i}-label`) as HTMLElement).innerText;
            if (isUsed) {
              for (const fullScope of globalStandardScopes) {
                if (fullScope.name === title) {
                  for (const thisScope of fullScope.deliverables) {
                    deliverables.push(thisScope);
                  }
                  break;
                }
              }
            }
          }
        }
        if (deliverables) {
          const content = new DeliverablesContent(context, cc);
          await content.insertList(deliverables);
        }
      }
    }
    await context.sync();
    scopeSpinner.classList.add("hide");
  });
}

async function setStaff(table: Word.Table, staff: [string, StaffMember][]) {
  let i = 0;
  for (const [role, employee] of staff) {
    console.log("staff:", employee);
    const c1 = table.getCell(Math.floor(i / 2), i % 2);
    c1.horizontalAlignment = "Centered";
    const rolePara = c1.body.insertParagraph(role, "End");
    rolePara.alignment = "Left";
    rolePara.style = "AFF Table Header";
    let photo: string | undefined = await getStaffPhoto(employee.id);
    console.log("photo:", photo);
    if (photo && photo !== "nophoto") {
      const pic = c1.body.insertInlinePictureFromBase64(photo, "End");
      // set size to 4.43x4.43
      pic.height = 124;
      pic.width = 124;
      pic.paragraph.alignment = "Centered";
    } else {
      c1.body.insertParagraph("No Picture", "End");
    }
    const b = c1.getBorder(Word.BorderLocation.bottom);
    b.type = "Single";
    b.width = 1;
    c1.body.insertParagraph("", "End");
    const pName = c1.body.insertParagraph(employee.name, "End");
    pName.font.size = 12;
    pName.font.color = "#FF8A29";
    pName.alignment = "Left";

    const pTitle = c1.body.insertParagraph(employee.title, "End");
    pTitle.font.size = 8;
    pTitle.font.color = "#000000";
    pTitle.alignment = "Left";
    const pQuals = c1.body.insertParagraph(employee.qualifications ? employee.qualifications : "", "End");
    pQuals.font.size = 8;
    pQuals.font.color = "#586B79";
    pQuals.alignment = "Left";
    i++;
  }
}
