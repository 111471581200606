// deno-lint-ignore-file
/* global document, console */

// import { SearchSettings } from "./search-settings";

export class FilterList {
  // TODO: save this value
  prevSearch: string = "";
  searchRegExp: boolean = true;
  stats?: HTMLElement;
  values: {
    element: HTMLLIElement;
    staffId: string;
    name: string;
  }[] = [];

  //   incLocal: Boolean = true;
  //   incS3: Boolean = true;
  //   incEC2: Boolean = true;
  //   incDeprecated: Boolean = false;
  //   incRegExp: Boolean = true;

  allRows: HTMLLIElement[] = [];
  constructor(public list: HTMLUListElement, public input: HTMLInputElement) {
    // load all the rows and sort into arrays
    this.loadTableData();
    // previous search term; used for speed optimisation
    this.prevSearch = "";
    // find statistics location
    // this.stats = document.getElementById("stat") as HTMLSpanElement;
    this.setStatistics(-1);
    // set up preferences
    this.initPreferences();
    // shows the searchfield
    console.log("adding event to:", this.input);
    this.input.addEventListener("keyup", () => this.quickSearch());
  }
  reInit() {
    this.loadTableData();
  }
  loadTableData() {
    // find table and appropriate rows
    const allRows = this.list.getElementsByTagName("li");

    // get data from each row
    this.values = new Array();

    for (const li of allRows) {
      this.values.push({
        element: li,
        staffId: li.id,
        name: li.getAttribute("data-name") as string,
        // hasLocal: parseBool(li.getAttribute("data-haslocal") as string),
        // hasS3: parseBool(li.getAttribute("data-hass3") as string),
        // hasEC2: parseBool(li.getAttribute("data-hasec2") as string),
        // isDeprecated: parseBool(li.getAttribute("data-deprecated") as string),
      });
    }
  }

  quickSearch() {
    console.log("quicksearching");
    let textRegExp;
    let t = this.input.value;
    if (!this.searchRegExp) {
      t = escapeRegExp(t);
    }
    // only search for valid RegExp
    try {
      textRegExp = new RegExp(t, "i");
      // this.closeAllInfo();
      this.input.className = "";
    } catch (err) {
      console.error(err);
      this.prevSearch = t;
      this.input.className = "invalidsearch";
      console.error("invalidsearch");
      return;
    }

    // count number of hits
    let hits = 0;
    let nitems = 0;
    for (const item of this.values) {
      console.log("item:", item);
      nitems++;
      let included = false;
      let found = false;

      //   if (this.incLocal && item.hasLocal) {
      //     included = true;
      //   }
      //   if (this.incS3 && item.hasS3) {
      //     included = true;
      //   }
      //   if (this.incEC2 && item.hasEC2) {
      //     included = true;
      //   }
      //   if (this.incDeprecated === false && item.isDeprecated) {
      //     included = false;
      //   }
      included = true;
      if (item.name.search(textRegExp) != -1) {
        found = true;
      }

      if (found && included) {
        // delete item.element.style.display;
        item.element.classList.remove("hide-staff-entry");
        hits++;
      } else {
        // item.element.style.display = "none";
        item.element.classList.add("hide-staff-entry");
      }
    }
    console.log(`hits: ${hits} nitems: ${nitems}`);
    // update statistics
    this.setStatistics(hits);
    // set previous search value
    this.prevSearch = this.input.value;
  }

  initPreferences() {
    // TODO: implement a different set of preferences
    // const searchSettings = document.querySelector("#search-settings search-settings") as SearchSettings;
    // if (this.incLocal) {
    //   searchSettings.incLocal = true;
    // }
    // if (this.incS3) {
    //   searchSettings.incS3 = true;
    // }
    // if (this.incEC2) {
    //   searchSettings.incEC2 = true;
    // }
    // if (this.incDeprecated) {
    //   searchSettings.incDeprecated = true;
    // }
    // if (this.searchRegExp) {
    //   searchSettings.incRegExp = true;
    // }
  }

  setStatistics(hits: number) {
    const numEntries = this.values && this.values.length;
    if (hits < 0) {
      hits = numEntries;
    }
    if (this.stats) {
      this.stats.innerText = `${hits}/${numEntries}`;
    }
  }

  showAll() {
    if (this.allRows) {
      for (const li of this.allRows) {
        li.classList.remove("hide-model-entry");
      }
    }
  }

  clearQS() {
    this.input.value = "";
    this.quickSearch();
  }

  //   updateSetting(obj: SearchSettings) {
  //     this.incLocal = obj.incLocal;
  //     this.incS3 = obj.incS3;
  //     this.incEC2 = obj.incEC2;
  //     this.incDeprecated = obj.incDeprecated;
  //     this.searchRegExp = obj.incRegExp;
  //     this.quickSearch();
  //   }

  toggleSettings() {
    const togglebutton = document.getElementById("showsettings") as HTMLElement;
    const settings = document.getElementById("settings") as HTMLElement;

    if (settings.className == "hidden") {
      settings.className = "show";
      togglebutton.innerText = "close settings";
      togglebutton.textContent = "close settings";
    } else {
      settings.className = "hidden";
      togglebutton.innerText = "settings...";
      togglebutton.textContent = "settings...";
    }
  }
}

// http://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
// NOTE: must escape every \ in the export code because of the JabRef Export...
function escapeRegExp(str: string) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
}
function parseBool(string: string): Boolean | undefined {
  if (string.toLowerCase() === "true") {
    return true;
  } else if (string.toLowerCase() === "false") {
    return false;
  }
  return;
}
