// deno-lint-ignore-file

import { dialogFallback } from "./fallbackauthdialog.ts";
import {
  callGetCfdLimitations,
  callGetPredefinedCompaniesList,
  callGetPredefinedFullScopesList,
  callGetPredefinedScopesList,
  callGetSmallStaffPhoto,
  callGetStaffList,
  callGetStandardFullScopeList,
  callGetStandardScopeList,
  callGetUserData,
  callSaveCustomCompany,
} from "./middle-tier-calls.ts";
import { showMessage } from "./message-helper.ts";
import { handleClientSideErrors } from "./error-handler.ts";
import { callGetStaffPhoto } from "./middle-tier-calls.ts";
import {
  Company,
  FullScope,
  MsStaffMember,
  PreDefinedFullScope,
  StaffMember,
} from "src/admin/data.ts";
import * as jose from "jose";

/* global OfficeRuntime, console */

let retryGetMiddletierToken = 0;
let middletierToken: string | undefined;
async function getPrivateData(reqFunction): Promise<any> {
  try {
    let isCurrent = false;
    if (middletierToken) {
      const tokenClaims = jose.decodeJwt(middletierToken);
      if (Date.now() / 1_000 < tokenClaims.exp - 10) {
        isCurrent = true;
      }
    }
    if (!isCurrent) {
      console.log("getting middletierToken");
      middletierToken = await OfficeRuntime.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
      });
    } else {
      console.log("middletierToken is cached and current");
    }
    console.log("middletier Token from office: ", middletierToken);
    let response: any = await reqFunction(middletierToken);
    if (!response) {
      throw new Error("Middle tier didn't respond");
    } else if (response.claims) {
      // Microsoft Graph requires an additional form of authentication. Have the Office host
      // get a new token using the Claims string, which tells AAD to prompt the user for all
      // required forms of authentication.
      console.log("issue with reqFunction:", response);
      let mfaMiddletierToken: string = await OfficeRuntime.auth.getAccessToken({
        authChallenge: response.claims,
      });
      response = reqFunction(mfaMiddletierToken);
    }

    // AAD errors are returned to the client with HTTP code 200, so they do not trigger
    // the catch block below.
    if (response.error) {
      const result = handleAADErrors(reqFunction, response);
      if (result) {
        return result;
      }
    } else {
      return response;
    }
  } catch (exception) {
    console.warn(exception);
    // if handleClientSideErrors returns true then we will try to authenticate via the fallback
    // dialog rather than simply throw and error
    if (exception.code) {
      if (handleClientSideErrors(exception)) {
        console.log("falling back to fallback dialogue 1");
        const result = await dialogFallback(reqFunction);
        console.log("dialog fallback result:", result);
        if (result) {
          return result;
        }
      }
    } else {
      showMessage("EXCEPTION: " + JSON.stringify(exception));
      throw exception;
    }
  }
}

export async function getUserData(): Promise<any> {
  try {
    let middletierToken: string = await OfficeRuntime.auth.getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    });
    let response: any = await callGetUserData(middletierToken);
    if (!response) {
      throw new Error("Middle tier didn't respond");
    } else if (response.claims) {
      // Microsoft Graph requires an additional form of authentication. Have the Office host
      // get a new token using the Claims string, which tells AAD to prompt the user for all
      // required forms of authentication.
      let mfaMiddletierToken: string = await OfficeRuntime.auth.getAccessToken({
        authChallenge: response.claims,
      });
      response = callGetUserData(mfaMiddletierToken);
    }

    // AAD errors are returned to the client with HTTP code 200, so they do not trigger
    // the catch block below.
    if (response.error) {
      const result = handleAADErrors(callGetUserData, response);
      return result;
    } else {
      return response;
    }
  } catch (exception) {
    // if handleClientSideErrors returns true then we will try to authenticate via the fallback
    // dialog rather than simply throw and error
    if (exception.code) {
      if (handleClientSideErrors(exception)) {
        console.log("falling back to fallback dialogue 2");
        const result = dialogFallback(getUserData);
        if (result) {
          return result;
        }
      }
    } else {
      showMessage("EXCEPTION: " + JSON.stringify(exception));
      throw exception;
    }
  }
}

export async function getStaffList(): Promise<StaffMember[]> {
  const staff: MsStaffMember[] = await getPrivateData(callGetStaffList);
  for (const employee of staff) {
    employee.name = employee.displayName;
    employee.title = employee.jobTitle;
  }
  return staff as StaffMember[];
}

export async function saveCustomCompany(customCompany: Company) {
  const staff: MsStaffMember[] = await getPrivateData((token) =>
    callSaveCustomCompany(token, customCompany)
  );
  for (const employee of staff) {
    employee.name = employee.displayName;
    employee.title = employee.jobTitle;
  }
  return staff;
}

export async function getStandardScopeList(): Promise<any[]> {
  return await getPrivateData(callGetStandardScopeList);
}

export async function getStandardFullScopeList(): Promise<FullScope[]> {
  return await getPrivateData(callGetStandardFullScopeList);
}

export async function getPredefinedScopeList(): Promise<any[]> {
  return await getPrivateData(callGetPredefinedScopesList);
}

export async function getPredefinedFullScopeList(): Promise<
  PreDefinedFullScope[]
> {
  return await getPrivateData(callGetPredefinedFullScopesList);
}

export async function getCfdLimitations(): Promise<any[]> {
  return await getPrivateData(callGetCfdLimitations);
}

export async function getPredefinedCompaniesList(): Promise<Company[]> {
  return await getPrivateData(callGetPredefinedCompaniesList);
}

export async function getStaffPhoto(id: string): Promise<string | undefined> {
  const s = await getPrivateData((middletierToken: string) =>
    callGetStaffPhoto(middletierToken, id)
  );
  if (s.length > 0) {
    return s;
  } else {
    return;
  }
}

export async function getSmallStaffPhoto(
  id: string,
): Promise<string | undefined> {
  const s = await getPrivateData((middletierToken: string) =>
    callGetSmallStaffPhoto(middletierToken, id)
  );
  if (s.length > 0) {
    return s;
  } else {
    return;
  }
}

async function handleAADErrors(reqFunction, response: any): Promise<any> {
  // On rare occasions the middle tier token is unexpired when Office validates it,
  // but expires by the time it is sent to AAD for exchange. AAD will respond
  // with "The provided value for the 'assertion' is not valid. The assertion has expired."
  // Retry the call of getAccessToken (no more than once). This time Office will return a
  // new unexpired middle tier token.

  if (
    response.error_description.indexOf("AADSTS500133") !== -1 &&
    retryGetMiddletierToken <= 0
  ) {
    retryGetMiddletierToken++;
    getPrivateData(reqFunction);
  } else {
    const result = await dialogFallback(reqFunction);
    return result;
  }
}
